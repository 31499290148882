import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  IconButton,
  Typography,
  TextField,
  Chip,
  makeStyles,
  Box,
  Divider,
} from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import { Add, ArrowBack, Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from 'src/store/reducers';
import mixPanel from 'src/utils/mixpanel';
import { ICarFromList } from 'src/types';
import { getAppointment } from 'src/api/quote';
import { setAppointment } from 'src/store/actions';
import reviewService from 'src/api/review';
import ModalBaseWhite from './ModalBaseWhite';
import VehicleForm from './VehicleForm';

import ServiceSelectionStep from './AppointmentSteps/ServiceSelectionStep';
import AppointmentSelectionstep from './AppointmentSteps/AppointmentSelectionStep';

import EstimateStep from './AppointmentSteps/EstimateReadyStep';
import GetBackRepair from './GetBackRepair';
import SuccessStepAppointment from './SuccessStepAppointment';

const useStyles = makeStyles((theme) => ({
  noBorder: {
    border: 'none',
    '& .MuiOutlinedInput-input': {
      border: 'none',
    },
  },
}));
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  id?: string;
}

interface StepProps {
  nextStep: () => void;
  prevStep: () => void;
  onClose: () => void;
}

interface Car {
  id: number;
  year: number | undefined;
  make: string;
  model: string;
  engine_size: string;
  vin: string;
  name: string;
}

interface Service {
  service_type: string;
  diagnostic_problems: string[];
  diagnostic_problem_description: string;
  repair_problems: string[];
}

interface Appointment {
  selected_day: string | undefined;
  selected_window: string | undefined;
  exact_address: string | undefined;
}

const VehicleStep: React.FC<
  StepProps & {
    carDetails: Car | undefined;
    setCarDetails: (car: Car | undefined) => void;
  }
> = ({ nextStep, prevStep, carDetails, setCarDetails, onClose }) => {
  const user = useSelector((state: IReduxState) => state.auth.user);
  const [filtered, setFiltered] = useState<ICarFromList[]>();
  const [addVehicle, setAddVehicle] = useState<boolean>(false);
  const [yearOptions, setYearOptions] = useState<string[] | undefined>();
  const [newCarAdded, setNewCarAdded] = useState<boolean>(false);
  const [msg, setMsg] = useState<string | null>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<ICarFromList | null>();

  const handleSubmit = () => {
    const reviewRequest = {
      user_id: user?.id,
      feedback: msg,
    };

    reviewService.submitReview(reviewRequest).then((res) => {
      setSubmitted(true);
    });
  };

  const classes = useStyles();

  useEffect(() => {
    if (user?.attributes.total_unique_cars) {
      const years = user.attributes.total_unique_cars.map((i) => i.one_string);
      setYearOptions(Array.from(new Set(years)).sort());

      setFiltered(user.attributes.total_unique_cars);
    }
  }, [user]);

  useEffect(() => {
    if (carDetails && carDetails.id) {
      const selectedCar = filtered?.find((car) => car.id === carDetails.id);
      setSelectedValue(selectedCar || null);
    } else {
      setSelectedValue(null);
    }
  }, [carDetails, filtered]);

  const handleSelectChange = (
    event: React.ChangeEvent<unknown>,
    value: ICarFromList | null
  ) => {
    if (value) {
      const carDetailsNew: Car = {
        id: value.id,
        year: parseInt(value.year, 10),
        make: value.make,
        model: value.model,
        engine_size: value.engine_size,
        vin: value.vin,
        name: value.name,
      };
      setCarDetails(carDetailsNew);
    }
  };

  return (
    <div>
      <Box style={{ margin: '20px 0' }}>
        <Typography style={{ fontFamily: 'Inter', marginBottom: 10 }}>
          Select New Vehicle
        </Typography>
        <FormControl fullWidth>
          <Autocomplete
            options={filtered || []}
            value={selectedValue}
            placeholder={
              selectedValue?.name !== null
                ? `${selectedValue?.name} | ${selectedValue?.year} ${selectedValue?.make} ${selectedValue?.model} ${selectedValue?.vin}`
                : `${selectedValue?.year} ${selectedValue?.make} ${selectedValue?.model} ${selectedValue?.engine_size} ${selectedValue?.vin}`
            }
            getOptionLabel={(option: ICarFromList) =>
              option.name !== null
                ? `${option.name} | ${option.year} ${option.make} ${option.model}`
                : `${option.year} ${option.make} ${option.model} ${option.engine_size} ${option.vin}`
            }
            onChange={handleSelectChange}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search..."
                variant="outlined"
                value={selectedValue?.one_string}
                className={classes.noBorder}
              />
            )}
          />
        </FormControl>

        {selectedValue && (
          <div
            style={{
              display: 'flex',
              marginTop: 20,
              alignItems: 'center',
              gap: 10,
            }}
          >
            <Chip
              style={{
                color: '#005959',
                background: '#E6EEEE',
                borderColor: '#005959',
                fontFamily: 'Inter',
              }}
              variant="outlined"
              label={
                selectedValue?.name !== null
                  ? `${selectedValue?.name} | ${selectedValue?.year} ${selectedValue?.make} ${selectedValue?.model}`
                  : `${selectedValue?.year} ${selectedValue?.make} ${selectedValue?.model} ${selectedValue?.engine_size}`
              }
            />
          </div>
        )}

        <Button
          variant="outlined"
          color="primary"
          onClick={() => setAddVehicle(true)}
          style={{
            marginTop: 20,
            background: '#E6EEEE',
            color: '#005959',
            border: 'none',
            borderRadius: '8px',
            fontFamily: 'Inter',
            textTransform: 'none',
          }}
        >
          <Add /> Add New Vehicle
        </Button>

        <div
          className="navigation-buttons"
          style={{ gap: 20, display: 'flex' }}
        >
          <Button
            onClick={() => onClose()}
            style={{
              marginTop: 20,
              background: '#F3F3F3',
              color: '#646867',
              border: 'none',
              borderRadius: '32px',

              padding: '10px 22px',
              display: 'flex',
              gap: 10,
            }}
          >
            <ArrowBack style={{ width: '25%' }} />
            <Typography
              style={{
                fontFamily: 'Inter',
                textTransform: 'none',
                fontSize: 20,
              }}
            >
              Back
            </Typography>
          </Button>
          <Button
            onClick={() => {
              mixPanel('Fleet Select Vehicle', user?.id);
              nextStep();
            }}
            disabled={!carDetails}
            style={{
              marginTop: 20,
              background: '#005959',
              color: '#E6EEEE',
              border: 'none',
              borderRadius: '32px',
              fontFamily: 'Inter',
              textTransform: 'none',
              padding: '10px 22px',
            }}
          >
            Next
          </Button>
        </div>

        <VehicleForm
          open={addVehicle}
          onClose={() => setAddVehicle(false)}
          type="create"
          carId={undefined}
          carDetails={carDetails}
          setNewCarAdded={() => setNewCarAdded(true)}
        />
      </Box>
    </div>
  );
};

const ServiceModal: React.FC<ModalProps> = ({ isOpen, onClose, id }) => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (id) {
      getAppointment(id).then((res) => {
        dispatch(setAppointment(res.data));
      });
      setCurrentStep(2);
    }
  }, [id, dispatch]);

  const appointment = useSelector(
    (state: IReduxState) => state.quote.appointment
  );

  const steps = ['Vehicle', 'Service', 'Appointment', 'Estimate', 'Success'];
  const [carDetails, setCarDetails] = useState<Car>();
  const [serviceDetails, setServiceDetails] = useState<Service>({
    service_type: '',
    diagnostic_problems: [],
    diagnostic_problem_description: '',
    repair_problems: [],
  });

  const [appointmentDetails, setAppointmentDetails] = useState<Appointment>();

  const nextStep = () => {
    setCurrentStep((prev) => (prev < steps.length - 1 ? prev + 1 : prev));
  };

  const prevStep = () => {
    setCurrentStep((prev) => (prev > 0 ? prev - 1 : prev));
  };

  if (!isOpen || (id && !appointment)) return null;

  return (
    <ModalBaseWhite open={isOpen} onClose={onClose}>
      <>
        <div style={{ display: 'flex' }}>
          <IconButton
            onClick={onClose}
            style={{ marginLeft: 'auto', marginRight: 0 }}
          >
            <Close />
          </IconButton>
        </div>
        {currentStep === 0 && (
          <VehicleStep
            nextStep={nextStep}
            prevStep={prevStep}
            carDetails={carDetails}
            setCarDetails={setCarDetails}
            onClose={onClose}
          />
        )}
        {currentStep === 1 && (
          <ServiceSelectionStep
            nextStep={nextStep}
            prevStep={prevStep}
            carDetails={carDetails}
            serviceDetails={serviceDetails}
            setServiceDetails={setServiceDetails}
          />
        )}

        {appointment?.attributes?.appointment_type && currentStep === 2 && (
          <>
            {(appointment?.attributes?.appointment_type === 'diagnosis' ||
              (serviceDetails?.service_type === 'repair' &&
                appointment?.attributes.estimate) ||
              id) && (
              <EstimateStep
                nextStep={nextStep}
                prevStep={prevStep}
                jobId={id}
              />
            )}
            {serviceDetails?.service_type === 'repair' &&
              !appointment?.attributes.estimate && <GetBackRepair />}
          </>
        )}

        {currentStep === 3 && (
          <AppointmentSelectionstep
            nextStep={nextStep}
            prevStep={prevStep}
            carDetails={carDetails}
            appointmentDetails={appointmentDetails}
            setAppointmentDetails={setAppointmentDetails}
          />
        )}
        {currentStep === 4 && <SuccessStepAppointment />}
      </>
    </ModalBaseWhite>
  );
};

ServiceModal.defaultProps = {
  id: undefined,
};

export default ServiceModal;
